
import { defineComponent } from 'vue';
//import CollapsibleButton from '@/components/util/CollapsibleButton.vue';
//import Collapsible from '@/components/util/Collapsible.vue';
import HighlightBox from '@/components/HighlightBox.vue';

export default defineComponent({
  components: {
    HighlightBox,
    //CollapsibleButton,
    //Collapsible,
  },
});
